<template>
    <router-link
        :to="{
            name: 'copyrightDetail',
            query: {
                id: info.id
            }
        }"
        class="funding"
    >
        <el-image style="margin-right: 20px" :src="getImg(info.img)" fit="cover"></el-image>
        <div class="content">
            <div class="name">
                {{ info.name }}
            </div>
            <div class="info">
                <div class="info-item">
                    <span class="text1">登记号：</span>
                    <span class="text2">{{ info.code }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">{{ `${this.type === 'COPY' ? '作品类型' : '软著类型'}` }}：</span>
                    <span class="text2">{{ info.workName }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">{{ info.type == 'COPY' ? '作者：' : '著作权人：' }}</span>
                    <span class="text2">{{ info.type == 'COPY' ? info.author.join(',') : info.owner.join(',') }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">价格：</span>
                    <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                    <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                </div>
                <!-- <div class="info-item">
                    <span class="text1">登记日期:</span>
                    <span class="text2">{{ info.registrationTime }}</span>
                </div> -->

                <div class="info-item">
                    <span class="text1">交易方式:</span>
                    <span class="text2">{{ getLabelName(info.tradingMethod, tradingMethodOption) }}</span>
                </div>
            </div>

            <div class="sub">
                {{ info.description }}
            </div>
        </div>
        <div class="edit-btn" v-if="isEdit">
            <el-button v-if="info.status === 'PASS'" type="primary" size="small" plain @click.prevent="edit"
                >编辑</el-button
            >
            <el-button v-if="info.status === 'PENDING'" type="primary" size="small" plain>审核中</el-button>
            <el-button v-if="info.status === 'DENY'" type="danger" size="small" plain>已拒绝</el-button>
            <el-button size="small" plain @click.prevent="del">删除</el-button>
        </div>
        <div class="btn" v-else>
            <div class="icon-button" @click.prevent="connectKefu">
                <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                咨询
            </div>
            <!-- <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
            </el-button> -->
        </div>
        <div class="look">
            <i class="iconfont iconfont-icon-lliulan"></i>
            <span>{{ info.view }}个人看过</span>
        </div>
    </router-link>
</template>
<script>
import comEvent from '../../mixins/comEvent';
import { tradingMethodOption } from '../../utils/variables';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        type() {
            return this.$route.query.type;
        }
    },
    data() {
        return {
            tradingMethodOption
        };
    },
    methods: {
        edit() {
            this.$router.push({
                path: '/CopyrightEdit',
                query: {
                    type: this.info.type,
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/copyright/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    },
    mixins: [comEvent]
};
</script>

<style lang="less" scoped>
.info {
    .flex();
    margin-top: 18px;
    flex-wrap: wrap;
    .info-item {
        width: 50%;
        padding-right: 20px;
        box-sizing: border-box;
        line-height: 32px;
        .flex();

        .text1 {
            font-size: 13px;
            color: #939599;
            display: inline-block;
            flex-shrink: 0;
            min-width: 70px;
        }

        .text2 {
            font-size: 13px;
            color: #000000;
            margin-left: 10px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.funding {
    padding: 20px;
    .flex();

    .el-image {
        width: 182px;
        height: 182px;
        flex-shrink: 0;
    }
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(56,85,142,0.04);

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81, 81, 81, 0.1);
        border: 1px solid #01a041;
        .text2 {
            color: #01a041;
        }
        .icon-button{
            background: #01A041;
            color: #FFFFFF;
        }
    }
}

.look {
    i {
        font-size: 18px;
        color: #c8c9cc;
        vertical-align: middle;
    }
    span {
        font-size: 13px;
        font-weight: 400;
        color: #818999;
        line-height: 18px;
        vertical-align: middle;
        margin: 0 2px 0 5px;
    }
    position: absolute;
    left: 220px;
    bottom: 22px;
}
.icon-button {
    width: 134px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #01a041;
    color: #01a041;
    box-sizing: border-box;
    padding: 0px;
}

.funding {
    .flex();
    .content {
        padding: 0 0 18px 0;
        flex-grow: 1;
        padding-right: 180px;
    }
}

.sub {
    font-size: 14px;
    color: #939599;
    line-height: 20px;
    margin-top: 10px;
}
.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
.name {
    font-size: 16px;
    font-weight: bold;
    color: #292c33;
    line-height: 22px;
}
</style>
