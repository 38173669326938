<template>
    <el-dialog
        v-loading="saving"
        :title="`发布供给信息-${formData.type === 'COPY' ? '版权' : '软著'}`"
        center
        :visible.sync="show"
        width="980px"
    >
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="132px"
            label-position="right"
            size="small"
            inline
        >
            <div class="content">
                <el-form-item prop="name" :label="`${formData.type === 'COPY' ? '作品名称' : '软件名称'}`">
                    <el-input
                        v-model="formData.name"
                        :placeholder="`${formData.type === 'COPY' ? '请输入作品名称' : '请输入软件名称'}`"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="registrationTime" label="登记日期">
                    <el-date-picker
                        v-model="formData.registrationTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="content">
                <el-form-item prop="code" :label="`${formData.type === 'COPY' ? '版本登记号' : '软著登记号'}`">
                    <el-input
                        v-model="formData.code"
                        :placeholder="`${formData.type === 'COPY' ? '例如：201620324442.1' : '例如：201620324442.1'}`"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="workType" :label="`${formData.type === 'COPY' ? '作品类型' : '软著类型'}`">
                    <el-select v-model="formData.workType" clearable filterable placeholder="请选择">
                        <el-option
                            v-for="item in workTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>

            <el-form-item class="block" prop="author" label="作者" v-if="formData.type == 'COPY'">
                <el-select
                    v-model="formData.author"
                    class="suffix"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入作者，按回车分割"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item prop="owner" class="block" label="著作权人">
                <el-select
                    v-model="formData.owner"
                    class="suffix"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入著作权人，按回车分割"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item prop="img" class="block" :label="`${type === 'COPY' ? '作品附图' : '软著附图'}`">
                <multi-upload class="imgBox" v-model="formData.img" :limit="6">
                    {{
                        `${
                            type === 'COPY'
                                ? '请上传作品附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                                : '请上传软著附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                        }`
                    }}
                </multi-upload>
            </el-form-item>
            <!-- <el-form-item prop="img" class="block" :label="`${formData.type === 'COPY' ? '作品附图' : '软著附图'}`">
                <multi-upload class="imgBox" v-model="formData.img" :limit="6">
                    {{
                        `${
                            formData.type === 'COPY'
                                ? '请上传作品附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                                : '请上传软著附图，格式JPG或PNG，大小不超过1M，最多可上传6张'
                        }`
                    }}
                </multi-upload>
            </el-form-item> -->
            <el-form-item class="block" prop="tradingMethodId" label="交易方式">
                <el-radio-group v-model="formData.tradingMethodId">
                    <el-radio v-for="item in tradingMethodOption" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <div class="content" style="padding-left: 30px">
                <el-form-item prop="expectedPrice" label="期望价（万元）">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        @change="Price"
                        :disabled="formData.negotiateDirectly"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="margin-right: 10px"
                    >
                    </el-input-number>
                    <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
                </el-form-item>
                <el-form-item prop="contact" label="联系人">
                    <el-input v-model="formData.contact"></el-input>
                </el-form-item>
            </div>
            <div class="content" style="padding-left: 40px">
                <el-form-item prop="basePrice" label="底价（万元）">
                    <el-input-number
                        v-model="formData.basePrice"
                        :min="0"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                    >
                    </el-input-number>
                </el-form-item>
                <el-form-item prop="phone" label="联系电话">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
            </div>
            <div class="content">
                <el-form-item prop="commissionType" label="佣金类型">
                    <el-radio-group v-model="formData.commissionType">
                        <el-radio v-for="item in commissionTypeOptions" :key="item.value" :label="item.value">
                            {{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="email" label="邮箱">
                    <el-input v-model="formData.email"></el-input>
                </el-form-item>
            </div>
            <el-form-item
                prop="commission"
                :label="`佣金${formData.commissionType == 'COMMISSION' ? '（万元）' : '（%）'}`"
            >
                <el-input-number
                    v-model="formData.commission"
                    :min="0"
                    :precision="1"
                    :controls="false"
                    placeholder="请输入"
                >
                </el-input-number>
            </el-form-item>

            <el-form-item class="block" prop="address" label="所在地区">
                <el-input class="suffix" placeholder="请输入所在地址" v-model="formData.address"></el-input>
            </el-form-item>
            <!-- <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary">
                            保存
                        </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item> -->
        </el-form>

        <div class="btns">
            <el-button type="info" size="small" @click="show = false">返回</el-button>
            <el-button type="primary" size="small" @click="onSave">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { tradingMethodOption, commissionTypeOptions, phonePattern } from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    name: 'CopyrightEdit',
    created() {
        if (this.$route.query.type) {
            this.formData.type = this.$route.query.type;
        }
    },

    data() {
        return {
            needChange: true,
            show: false,
            saving: false,
            formData: {},
            workTypeOptions: [],
            tradingMethodOption,
            commissionTypeOptions
        };
    },
    computed: {
        ...mapState(['userInfo']),
        types() {
            return this.$route.query.type;
        },
        rules() {
            return {
                name: [{ required: true, message: `请输入${this.types === 'COPY' ? '作品' : '软著'}名称` }],
                code: [
                    {
                        required: true,
                        message: `请输入${this.types === 'COPY' ? '版本' : '软著'}登记号`
                    }
                ],
                // workType: [{ required: true, message: `请选择${this.types === 'COPY' ? '作品' : '软著'}类型` }],
                // author: [{ required: true, message: '请输入作者，按回车分割' }],
                // owner: [{ required: true, message: '请输入著作权人' }],
                // img: [
                //     {
                //         required: true,
                //         message: `请上传${this.types === 'COPY' ? '作品' : '软著'}附图`
                //     }
                // ],
                // tradingMethodId: [{ required: true, message: '请选择交易方式' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择期望价方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // basePrice: [{ required: true, message: '请输入底价', trigger: 'blur' }],
                // contact: [{ required: true, message: '请输入联系人' }],
                // phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                // commissionType: [{ required: true, message: '请选择佣金类型' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // commission: [{ required: true, message: '请输入佣金', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区' }]
            };
        }
    },
    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        init(types = 'SOFT') {
            let flag = 4;
            if (types == 'COPY') {
                flag = 3;
            }
            this.$http
                .post('/setting/byFlag', { flag: flag })
                .then(res => {
                    this.workTypeOptions = res.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });

            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.formData = {
                    userId: this.userInfo.id,
                    type: types,
                    author: [],
                    owner: [],
                    // tradingMethod: 'TRANSFER',
                    // commissionType: 'COMMISSION',
                    negotiateDirectly: false,
                    contact: this.userInfo.nickname,
                    phone: this.userInfo.phone,
                    email: this.userInfo.email
                };
            });
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.init(this.types);
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, status: 'PENDING' };
            // console.log(data);
            // data.owner = data.owner.join(',');
            // data.author = data.author.join(',');
            this.saving = true;
            this.$http
                .post('/copyright/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.show = false;
                    this.$emit('refreash');
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/copyright/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
.suffix {
    width: 400px !important;
    /deep/ .el-input__suffix {
        opacity: 0;
    }
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
.content {
    display: flex;
}
/deep/ .el-dialog__header {
    padding: 17px 0px !important;
}
/deep/ .el-form {
    width: 100% !important;
    margin: 0px !important;
}
</style>
