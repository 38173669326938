<template>
    <filter-list
        :sortKey="sortKey"
        v-model="list"
        ref="list"
        url="/copyright/all"
        :filters="filters"
        @btnEvent="addInfo"
        @init="init"
    >
        <template v-for="item in list">
            <copyright-row :key="item.id" :info="item"></copyright-row>
        </template>
        <copyright-add ref="public" @refreash="refreash"></copyright-add>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import { tradingMethodOption, moneyOptions } from '../../utils/variables';
import CopyrightRow from '../../components/list/CopyrightRow.vue';
import CopyrightAdd from '../../components/popup/CopyrightAdd.vue';
export default {
    components: { FilterList, CopyrightRow, CopyrightAdd },
    data() {
        return {
            tradingMethodOption,
            moneyOptions,
            workTypeeOptions: [], //融资目的,
            list: [],
            sortKey: [
                // {
                //     name: '发布时间',
                //     key: 'createdAt'
                // },
                // {
                //     name: '价格区间',
                //     key: 'expectedPrice'
                // },
                {
                    name: '浏览量',
                    key: 'view'
                }
            ]
        };
    },
    created() {
        this.init();
    },
    computed: {
        type() {
            return this.$route.query.type;
        },
        filters() {
            return [
                {
                    name: `${this.type === 'COPY' ? '作品类型' : '软著类型'}`,
                    key: 'workType',
                    list: [...this.workTypeeOptions]
                },
                {
                    name: '交易方式',
                    key: 'tradingMethodId',
                    list: [...this.tradingMethodOption]
                }
                // {
                //     name: '价格区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // }
            ];
        },
        createdAtOptions() {
            return [
                {
                    label: '一周内',
                    value: this.getTimeBefor()
                },
                {
                    label: '一月内',
                    value: this.getTimeOnem()
                },
                {
                    label: '二月内',
                    value: this.getTimeTom()
                },
                {
                    label: '三月内',
                    value: this.getTimeTherrm()
                },
                {
                    label: '三月以上',
                    value: this.getTimeTherrms()
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: this.$route.query.type == 'COPY' ? 3 : 4 })
                .then(res => {
                    if (res.length > 0) {
                        this.workTypeeOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        addInfo(value) {
            if (value) {
                // this.$refs.public.init(this.$route.query.type);
                this.checkLogin(false).then(() => {
                    this.$refs.public.init(this.$route.query.type);
                });
            }
        },
        refreash() {
            this.$refs.list.getData();
        }
    }
};
</script>

<style lang="less" scoped></style>
